body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'GravityLight';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    mono
    space;
}

@font-face {
  font-family: 'GravityLight';
  src: local('GravityLight'), url(./assets/fonts/Gravity/Gravity-Light.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
